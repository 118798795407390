import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {BasketRequest} from '@/types/BasketRequest'
import {ReportDates} from '@/types/ReportDates'
import {MapChartData} from '@/types/MapChartData'
import useDateFormats from '@/uses/useDateFormats'
const {formatDateToApi} = useDateFormats()

export default class MapApiService extends BaseApiService {
  async getBasketsPriceChangeService(
    basket: BasketRequest,
    graphType = 1,
    reportDates = new ReportDates()
  ): Promise<BaseData<MapChartData>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<MapChartData>>(
        `report/get-map-cpi/${basket.id}/${graphType}`,
        {
          params: {
            base_date_begin: formatDateToApi(reportDates.base_date_begin),
            base_date_end: formatDateToApi(reportDates.base_date_end),
            report_date_begin: formatDateToApi(reportDates.report_date_begin),
            report_date_end: formatDateToApi(reportDates.report_date_end),
            category_id: basket.product_and_service_id
              ? null
              : basket.category_id,
            product_and_service_id: basket.product_and_service_id,
          },
        }
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getMapInfoService(
    basket: BasketRequest,
    graphType = 1,
    reportDates = new ReportDates()
  ): Promise<BaseData<MapChartData>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<MapChartData>>(
        `report/get-country-cpi/${basket.id}/${graphType}`,
        {
          params: {
            base_date_begin: formatDateToApi(reportDates.base_date_begin),
            base_date_end: formatDateToApi(reportDates.base_date_end),
            report_date_begin: formatDateToApi(reportDates.report_date_begin),
            report_date_end: formatDateToApi(reportDates.report_date_end),
            category_id: basket.product_and_service_id
              ? null
              : basket.category_id,
            product_and_service_id: basket.product_and_service_id,
          },
        }
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
