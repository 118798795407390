import {createStore} from 'vuex'
import {GraphPeriodTypes} from '@/enums/GraphPeriodTypes'
import {TopReportDates} from '@/types/TopReportDates'
import {ReportDates} from '@/types/ReportDates'
import {BasketRequest} from '@/types/BasketRequest'
import graph from '@/store/modules/graph'
import {Basket} from '@/types/Basket'

export default createStore({
  state: {
    basket: {
      id: '',
      category_id: null,
      product_and_service_id: null,
    },
    basketData: [] as Basket[],
    basketName: '',
    region: '29a78355-9d07-4af5-8d68-e746fe2f5bec',
    graphPeriodType: {
      text: '',
      disabled: false,
      type: null,
      description: '',
    },
    year: new Date().getFullYear(),
    currency: {
      id: 'bf57bd0f-2a7e-4cfd-bd90-0a736f3eaebd',
      code: 'RUB',
      name: 'Рубль',
    },
    topReportDates: {
      report_date_begin: new Date(new Date().getFullYear(), 0, 1),
      report_date_end: new Date(),
    },
    reportDates: new ReportDates(),
    user_guide: '',
    has_user_guide: false,
    create_basket: {
      title: 'Создание корзины',
      description: 'Здесь будет сформирована ваша корзина..',
    },
  },
  getters: {
    basket: function (state): BasketRequest {
      return state.basket
    },
    basketName: function (state) {
      return state.basketName
    },
    basketData: function (state) {
      return state.basketData
    },
    region: function (state) {
      return state.region
    },
    graphPeriodType: function (state) {
      return state.graphPeriodType
    },
    year: function (state) {
      return state.year
    },
    currency: function (state) {
      return state.currency
    },
    topReportDates: function (state): TopReportDates {
      return state.topReportDates
    },
    reportDates: function (state): ReportDates {
      return state.reportDates
    },
    user_guide: function (state) {
      return state.user_guide
    },
    has_user_guide: function (state) {
      if (state.user_guide != '') return true
      else return false
    },
    create_basket: function (state) {
      return state.create_basket
    },
  },
  mutations: {
    UPDATE_BASKET(state, payload) {
      state.basket = payload
    },
    UPDATE_REGION(state, payload) {
      state.region = payload
    },
    UPDATE_BASKETNAME(state, payload) {
      state.basketName = payload
    },
    UPDATE_BASKET_DATA(state, payload) {
      state.basketData = payload
    },
    UPDATE_GRAPH_PERIOD_TYPE(state, payload) {
      state.graphPeriodType = payload
    },
    UPDATE_YEAR(state, payload) {
      state.year = payload
    },
    UPDATE_CURRENCY(state, payload) {
      state.currency = payload
    },
    UPDATE_TOP_REPORT_DATES(state, payload: TopReportDates) {
      state.topReportDates = payload
    },
    UPDATE_REPORT_DATES(state, payload: ReportDates) {
      state.reportDates = payload
    },
    UPDATE_USER_GUIDE(state, payload) {
      state.user_guide = payload
      state.has_user_guide = true
      state.create_basket = {
        title: 'Корзина',
        description: 'для просмотра нажмите на секции',
      }
    },
  },
  actions: {
    updateBasket(context, payload: BasketRequest) {
      context.commit('UPDATE_BASKET', payload)
    },
    updateBasketName(context, payload) {
      context.commit('UPDATE_BASKETNAME', payload)
    },
    updateRegion(context, payload) {
      context.commit('UPDATE_REGION', payload)
    },
    updateGraphPeriodType(context, payload) {
      context.commit('UPDATE_GRAPH_PERIOD_TYPE', payload)
    },
    updateYear(context, payload) {
      context.commit('UPDATE_YEAR', payload)
    },
    updateCurrency(context, payload) {
      context.commit('UPDATE_CURRENCY', payload)
    },
    updateTopReportDates(context, payload: TopReportDates) {
      context.commit('UPDATE_TOP_REPORT_DATES', payload)
    },
    updateReportDates(context, payload: ReportDates) {
      context.commit('UPDATE_REPORT_DATES', payload)
    },
    updateUserGuide(context, payload) {
      context.commit('UPDATE_USER_GUIDE', payload)
    },
  },
  modules: {
    graph: graph,
  },
})
