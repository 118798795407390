<template>
  <v-row class="py-7">
    <v-col cols="12">
      <div class="cpi-block cpi-partners">
        <div class="cpi-block">
          <p class="cpi-block_text text-center">
            Kursiv-CPI – совместная инициатива аналитиков Freedom Broker и
            республиканского делового еженедельника «Курсив»
          </p>
          <p class="cpi-block_text text-center">
            Это исследование не претендует на общенациональный обзор, а лишь
            фиксирует возможный диапазон инфляции при использовании различных
            источников данных об изменении цен
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
