import {ref} from 'vue'
import {useRoute} from 'vue-router'

export default function () {
  const route = useRoute()
  const itemPerPage = 10
  const page = ref(route.query.page ? +route.query.page : 1)
  const totalPage = ref(1)

  const getRowNumber = (index: number) => {
    return index + 1 + (page.value - 1) * itemPerPage
  }

  return {
    itemPerPage,
    page,
    totalPage,
    getRowNumber,
  }
}
