import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewBasketView from '../views/NewBasketView.vue'
import CustomBasketView from '../views/CustomBasketView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/custom-basket',
    name: 'CPICreate',
    component: NewBasketView,
  },
  {
    path: '/basket/:user_guide/:basket_id',
    name: 'CPICustomBasketView',
    component: CustomBasketView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

export default router
