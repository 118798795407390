import {LinearPoint} from '@/types/LinearPoint'

export class LinearDataSet {
  label?: string
  type = 'line'
  data = [] as LinearPoint[]
  fill = true
  borderColor = '#0066CC'
  tension = 0
  backgroundColor = '#0066CC1A'
  pointRadius = 3
}
