import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {OnBoarding} from '@/types/OnBoarding'

export default class OnBoardingApiService extends BaseApiService {
  async getData(): Promise<BaseData<OnBoarding>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<OnBoarding>>(
        '/on_boarding_data'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
