// Styles
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import {createVuetify} from 'vuetify'

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#72BF44',
        },
      },
    },
  },
})
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
