import {GraphButton} from '@/types/GraphButton'
import {GraphPeriodTypes} from '@/enums/GraphPeriodTypes'

export default function () {
  const graphButtons: GraphButton[] = [
    // {
    //   text: '1 D',
    //   disabled: false,
    //   type: GraphPeriodTypes.CurrentDayToPrevDay,
    //   description: 'к предыдущему дню',
    // },
    // {
    //   text: '1 W',
    //   disabled: false,
    //   type: GraphPeriodTypes.ToCorrespondingMonthPrevWeek,
    //   description: 'к предыдущей неделе',
    // },
    // {
    //   text: 'MoM',
    //   disabled: false,
    //   type: GraphPeriodTypes.CurrentMonthToPrevMonth,
    //   description: 'к предыдущему месяцу',
    // },
    // {
    //   text: 'MTD',
    //   disabled: false,
    //   type: GraphPeriodTypes.EndMonthToBeginMonth,
    //   description: 'к началу месяца',
    // },
    {
      text: 'YTD',
      disabled: false,
      type: GraphPeriodTypes.ToYearBegin,
      description: 'к началу года',
    },
    {
      text: 'SPLY',
      disabled: false,
      type: GraphPeriodTypes.ToCorrespondingMonthPrevYear,
      description: 'к соответствующему месяцу предыдущего года',
    },
  ]
  return {
    graphButtons,
  }
}
