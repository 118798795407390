import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import {createMetaManager, plugin as metaPlugin} from 'vue-meta'
import {createI18n} from 'vue-i18n'
import './custom'

const i18n = createI18n({
  // something vue-i18n options here ...
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(vuetify)
  .use(i18n)

router.isReady().then(() => app.mount('#app'))
