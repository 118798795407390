<template>
  <div id="sequence"></div>
  <div id="chart" :class="skeleton ? 'image-placeholder' : ''">
    <div id="explanation" class="" style="visibility: hidden">
      <span id="percentage"></span>
      <span id="basketName"></span>
    </div>
    <svg id="d3chart"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import * as _data from '../js/dataJsond3'
export default {
  name: 'vue-d3-chart',
  props: ['BasketData', 'Basketwidth'],
  data() {
    return {
      data: {name: 'basket', children: []},
      line: '',
      width: 800,
      height: 300,
      radius: 100,
      root: null,
      g: null,
      path: null,
      label: null,
      parent: null,
      center: null,
      skeleton: false,
    }
  },
  computed: {
    basketId() {
      return this.$store.state.basket.id
    },
  },
  mounted() {
    console.log('D3 chart')
    if (this.BasketData.length < 1) {
      console.log('empty')
      this.data.children = _data.SunburstData
      this.skeleton = true
      console.log(this.data.children)
    } else {
      this.data.children = this.BasketData
    }
    this.calculatePath()
    this.findAndClickBasketByBasketId(this.basketId)
  },
  methods: {
    calculatePath() {
      this.root = this.partition()
      //this.width = window.innerWidth / 2 - 100
      //this.radius = this.width / ((this.root.height + 1) * 2)
      //this.width = (this.root.height + 1) * this.radius * 2
      this.root.each((d) => {
        d.current = d
        d.current.radius = this.radius / 2
      })
      const svg = d3
        .select('#d3chart')
        .attr('viewBox', [0, 0, this.width, this.width / 2 + 100])

      this.g = svg
        .append('g')
        .attr(
          'transform',
          `translate(${this.width / 2},${this.width / 4 + 50})`
        )

      this.path = this.g
        .append('g')
        .selectAll('g')
        .data(this.root.descendants().slice(1))
        .enter()
        .append('path')
        .attr('id', (d, i) => {
          if (this.skeleton) return ''
          else return 'monthArc_' + i
        })
        .join('path')
        .attr('stroke', 'white')
        .attr('fill', (d) => {
          const dep = d.depth
          while (d.depth > 2) d = d.parent
          if (d.depth == 1 || this.skeleton) return '#c7c3c3'
          return d3.rgb(this.color(d.data.name)).darker(dep - 1)
        })
        //.attr('fill-opacity', (d) => this.arcVisible(d.current))
        .attr('pointer-events', (d) =>
          this.arcVisible(d.current) ? 'auto' : 'none'
        )
        .attr('d', (d) => this.arc(d.current))
      if (!this.skeleton) {
        this.path
          //.filter((d) => d.children)
          .style('cursor', 'pointer')
          .on('click', this.clicked)
          .on('mouseover', this.mouseover)
        // Add the mouseleave handler to the bounding circle.
        this.g.on('mouseleave', this.mouseleave)
      }

      this.label = this.g
        .append('g')
        .selectAll('text')
        .data(this.root.descendants().slice(1))
        .enter()
        .append('text')
        .join('text')
        .attr('class', 'basketLabel')
        //.attr("dx", (d) => this.labelCenter(d.current))
        .attr('dx', 5) //Move the text from the start angle of the arc
        .attr('dy', 28) //Move the text down
        .append('textPath')
        .attr('fill-opacity', (d) => +this.labelVisible(d.current))
        .attr('xlink:href', (d, i) => {
          if (this.skeleton) return ''
          else return '#monthArc_' + i
        })
        .attr('d', (d) => this.arc(d.current))
        .text((d) => this.labelTransform(d.current))

      this.parent = this.g
        .append('circle')
        .datum(this.root)
        .attr('r', this.radius)
        .attr('fill', '#f0f0f0')
        .attr('stroke', 'white')
        .attr('pointer-events', 'all')
        .on('click', this.clicked)
      this.center = this.g
        .append('text')
        .datum(this.root)
        .attr('class', 'centerLabel')
        .text('выберите секцию')
        // .attr("stroke", "#919191")
        // .attr("font-size", (d) => {
        //   return this.radius / ((this.radius * 11) / 100);
        // })
        .attr('dy', (d) => {
          return this.radius / ((this.radius * 25) / 100)
        })
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .on('click', this.clicked)
      // create a tooltip
      return svg.node()
    },
    arcVisible(d) {
      return d.depth > 0 ? 1 - (d.depth - 1) * 0.2 : 1
    },
    labelVisible(d) {
      return d.y1 <= 6 && d.y0 >= 0 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
    },
    fitStringToWidth(str, width) {
      if (width > 0) {
        var span = document.createElement('span')
        span.style.display = 'inline'
        span.style.visibility = 'hidden'
        span.style.padding = '0px'
        span.style.fontSize = 16 + 'px'
        span.style.font = 'times new roman'
        document.body.appendChild(span)
        var result = str // default to the whole string
        span.innerHTML = result
        if (span.offsetWidth > width) {
          var posStart = 0,
            posMid,
            posEnd = str.length,
            posLength
          while ((posLength = posEnd - posStart) > 1) {
            span.innerHTML = str.substring(0, posLength)
            if (span.offsetWidth > width - 13) posStart++
            else break
          }
          result = str.substring(0, posLength - 1) + '...'
        }
        document.body.removeChild(span)
        return result
      }
    },
    labelTransform(d) {
      var radius = Math.max(d.y0 * 25, d.y1 * 25 - 1)
      var C = d.x1 - d.x0
      var arc = (C * radius) / 1
      var text = d.data.name.length
      var points = arc > 3 ? '...' : ''
      return text > arc
        ? d.data.name.substring(0, arc - 0) + points
        : d.data.name
    },
    labelCenter(d) {
      var radius = Math.max(d.y0 * this.radius, d.y1 * this.radius - 1) - 28
      var C = d.x1 - d.x0
      var arc = C * radius
      var text = d.data.name.length * 5
      return text < arc ? (arc - text) / 2 : 5
    },
    getAncestors(node) {
      var path = []
      var current = node
      while (current.parent) {
        path.unshift(current)
        current = current.parent
      }
      return path
    },
    mouseover(d, i) {
      var percentage = i.data.value
        ? Number(i.data.value).toFixed(1) + '%' + ' от '
        : ''
      var percentageString = i.data.name + ' ' + percentage
      d3.select('#percentage').text(percentageString)
      var basketName = i.parent.data.id != undefined ? i.parent.data.name : ''
      d3.select('#basketName').text(basketName)
      var left = d.offsetX < this.radius ? -(d.offsetX + 70) : d.offsetX + 70
      d3.select('#explanation')
        .style('visibility', '')
        .style('left', left + 'px')
        .style('top', d.offsetY + 'px')
      this.path.style('opacity', 0.3)
      var sequenceArray = this.getAncestors(i)
      this.path
        .filter(function (node) {
          return sequenceArray.indexOf(node) >= 0
        })
        .style('opacity', 1)
    },
    mouseleave(d, i) {
      this.path.style('opacity', 1)
      d3.select('#explanation').style('visibility', 'hidden')
      if (this.Tooltip !== undefined) {
        this.Tooltip.style('opacity', 0)
        d3.select(this).style('stroke', 'none').style('opacity', 0.8)
      }
    },
    clicked(event, p) {
      if (p.height === 3 && p.depth === 0 && event !== false) return
      //if (p.height === 0 && p.depth === 3 && event !== false) return
      if (p.height === 2 && p.depth === 1) {
        this.center.text('')
        this.center.append('tspan').text('Вес корзины ')
        this.center
          .append('tspan')
          .text('100%')
          .attr('dy', 25)
          .attr('x', 0)
          .attr('dx', 0)
      } else {
        this.center.text('')
        this.center.append('tspan').text('Вес корзины ')
        this.center
          .append('tspan')
          .text(Number(p.data.value).toFixed(2) + '%')
          .attr('dy', 25)
          .attr('x', 0)
          .attr('dx', 0)
      }
      if (p.data.id !== undefined) {
        switch (p.depth) {
          case 1:
            this.$store.dispatch('updateBasket', {
              id: p.data.id,
              category_id: null,
              product_and_service_id: null,
            })
            break
          case 2:
            this.$store.dispatch('updateBasket', {
              id: this.$store.getters.basket.id ?? p.parent.data.id,
              category_id: p.data.id,
              product_and_service_id: null,
            })
            break
          case 3:
            this.$store.dispatch('updateBasket', {
              id: this.$store.getters.basket.id ?? p.parent.data.id,
              category_id:
                this.$store.getters.basket.category_id ?? p.parent.data.id,
              product_and_service_id: p.data.id,
            })
            break
          default:
            break
        }
      }
      const x0Max = (value, selectedValue) => {
        return Math.max(
          0,
          Math.min(
            1,
            (value.x0 - selectedValue.x0) /
              (selectedValue.x1 - selectedValue.x0)
          )
        )
      }
      const x1Max = (value, selectedValue) => {
        return Math.max(
          0,
          Math.min(
            1,
            (value.x1 - selectedValue.x0) /
              (selectedValue.x1 - selectedValue.x0)
          )
        )
      }
      const y0Max = (value, selectedValue) => {
        var ad = 0
        if (selectedValue.depth > 1) ad = value.depth
        if (selectedValue.depth == 3 && value.depth == 3) ad = value.depth + 1
        if (value.depth == 1) return 0
        return Math.max(0, value.y0 - selectedValue.depth) + ad
      }
      const y1Max = (value, selectedValue) => {
        var ad = 0
        if (selectedValue.depth > 1) ad = value.depth + 1
        if (selectedValue.depth == 3 && value.depth > 1) ad = value.depth + 2
        return Math.max(0, value.y1 - selectedValue.depth) + ad
      }
      const radMax = (value, selectedValue, raduis) => {
        var ad = raduis
        if (selectedValue.depth > 1) ad = raduis / 2
        if (value.depth < 1) ad = 0
        return ad
      }
      this.parent.datum(p.parent || this.root)
      const rad = this.radius
      this.root.each((d) => {
        return (d.target = {
          x0: x0Max(d, p) * 2 * Math.PI,
          x1: x1Max(d, p) * 2 * Math.PI,
          y0: y0Max(d, p),
          y1: y1Max(d, p),
          radius: radMax(d, p, rad),
        })
      })

      const t = this.g.transition().duration(750)
      const functionArcVisible = this.arcVisible
      const functionlabelVisible = this.labelVisible
      const countVisibleCharacters = this.countVisibleCharacters
      const fitStringToWidth = this.fitStringToWidth
      this.path
        .transition(t)
        .tween('data', (d) => {
          const i = d3.interpolate(d.current, d.target)
          d.current.radius = radMax(d, p, rad)
          return (t) => (d.current = i(t))
        })
        .attrTween('d', (d) => () => this.arc(d.current))

      this.label
        .filter(function (d) {
          return (
            +this.getAttribute('fill-opacity') || functionlabelVisible(d.target)
          )
        })
        .transition(t)
        .attr('fill-opacity', (d) => +functionlabelVisible(d.target))
        .text(function (d) {
          var radius = Math.min(d.y0 * 50, (d.y1 + 0) * 50) + rad - 34
          var C = d.target.x1 - d.target.x0
          var arcLength = C * radius
          var visible = fitStringToWidth(d.data.name, arcLength)
          var points = ''
          return visible
        })
    },
    check_val(d) {
      return d.children ? 0 : d.value
    },
    partition() {
      const root = d3
        .hierarchy(this.data)
        .sum((d) => this.check_val(d))
        .sort((a, b) => b.height - a.height || b.value - a.value)
      return d3.partition().size([2 * Math.PI, root.height + 1])(root)
    },
    format: d3.format(',d'),
    color: d3.scaleOrdinal([
      '#31747e',
      '#203b5e',
      '#d01f4a',
      '#2A7EA8',
      '#175e29',
      '#bd4e8d',
      '#BE3A26',
      '#6d5092',
      '#6d5092',
    ]),
    // color: d3.scaleOrdinal(
    //   d3.quantize(d3.interpolateHcl('#60c96e', '#4d4193'), 8)
    // ),
    arc: d3
      .arc()
      .startAngle((d) => d.x0 - Math.PI / 2)
      .endAngle((d) => d.x1 - Math.PI / 2)
      // .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      // .padRadius((d) => d.radius * 1.5)
      // .innerRadius((d) => d.y0 * d.radius)
      // .outerRadius((d) =>
      //   Math.max(d.y0 * d.radius, d.y1 * d.radius - 1)
      // ),
      .innerRadius((d) => d.y0 * (d.radius / 2) + 100)
      .outerRadius((d) => d.y1 * (d.radius / 2) + 100),
    findAndClickBasketByBasketId(basketId) {
      const element = this.path._groups[0].find(
        (element) => element.__data__.data.id === basketId
      )
      this.clicked(false, element.__data__)
    },
  },
  watch: {
    basketId(newValue, oldValue) {
      this.findAndClickBasketByBasketId(newValue)
    },
  },
}
</script>
