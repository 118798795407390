<template>
  <svg id="MAPchart" width="auto">
    <g class="info"></g>
    <g class="map"></g>
    <g class="label"></g>
  </svg>
</template>
<script>
import * as d3 from 'd3'
import axios from 'axios'
export default {
  name: 'vue-d3-map-chart',
  props: ['MapData', 'MapInfo'],
  data() {
    return {
      statesJson: null,
      happiestCities: [], // Shown above and in JSFiddle
      happiestStates: [], // Shown above and in JSFiddle
      width: 1600,
      height: 640,
      svg: null,
      path: null,
      label: null,
      cpiValue: null,
      info: null,
      geojson: {},
      default: false,
    }
  },
  methods: {
    calculatePath() {
      this.svg = d3
        .select('#MAPchart')
        .attr('viewBox', [0, 0, this.width, this.height])
      this.path = this.svg
        .select('g.map')
        .selectAll('path')
        .data(this.geojson.features)
      let projection = d3.geoMercator().fitSize([1600, 640], {
        type: 'FeatureCollection',
        features: this.geojson.features,
      })

      let geoGenerator = d3.geoPath().projection(projection)
      this.path
        .enter()
        .append('path')
        .join('path')
        .attr('stroke', '#7B7B7B')
        .attr('clicked', false)
        .style('stroke-width', '0.5')
        .attr('cursor', 'pointer')
        .attr('fill', (d) => {
          if (d.properties.TYPE_2 == 'Rayon') return '#333333'
          return d3.rgb(this.color())
        })
        .style('pointer-events', 'auto')
        .attr('d', geoGenerator)
        .on('mouseover', function (d, i) {
          if (d3.select(this).attr('clicked') == 'false') {
            d3.select(this).attr('fill', '#7B7B7B')
          }
        })
        .on('mouseout', function (d, i) {
          if (d3.select(this).attr('clicked') == 'false') {
            d3.select(this).attr('fill', '#dddddd')
          }
        })
        .on('click', this.clicked)
      this.label = this.svg
        .select('g.label')
        .selectAll('text')
        .data(this.geojson.features)
      this.label
        .enter()
        .append('text')
        .join('text')
        .attr('class', 'mapLabel')
        .attr('x', function (d) {
          if (d.properties.TYPE_2 == 'Rayon')
            return geoGenerator.centroid(d)[0] + 60
          return geoGenerator.centroid(d)[0]
        })
        .attr('y', function (d) {
          return geoGenerator.centroid(d)[1]
        })
        .style('cursor', 'pointer')
        .on('click', this.clicked)
        .attr('text-anchor', 'middle')
        .append('tspan')
        .attr('class', 'title')
        .attr('dy', '0em')
        .text((d) => this.labelTransform(d))
      this.label.selectAll('tspan.cpi').remove()
      this.label
        .append('tspan')
        .attr('class', 'cpi')
        .attr('dy', '24px')
        .attr('x', function (d) {
          let currentX = d3.select(this.parentNode).attr('x')
          return currentX
        })
        .text((d) => this.cpiTransform(d))
    },
    writeinfo() {
      this.svg.select('g.info').selectAll('*').remove()
      this.info = this.svg
        .select('g.info')
        .data(this.geojson.features)
        .append('text')
        .attr('x', (d) => {
          return this.width - 300
        })
      this.info
        // .enter()
        .append('tspan')
        .attr('class', 'infoLabel')
        .text('Kursiv-CPI ' + this.$store.getters.graphPeriodType.description)
        .attr('dy', (d) => {
          return 20
        })
        .attr('pointer-events', 'none')
      this.info
        .append('tspan')
        .attr('class', 'infoLabel')
        .attr('dy', 24)
        .attr('x', (d) => {
          return this.width - 300
        })
        .text('по всему Казахстану ')
      this.info
        .append('tspan')
        .attr('class', 'infoValue')
        .text(this.MapInfo.cpi + '%')
    },
    partition() {
      const root = d3.hierarchy(this.data)
      return d3.partition()(root)
    },
    handleMouseover(e, p) {
      const colorPathFucntion = this.colorPath
      const color = '#0071E3'
      this.path.attr('fill', (d) => colorPathFucntion(d, p, color))
    },
    clicked(event, p) {
      const graphPeriodType = this.$store.getters.graphPeriodType.description
      const writeinfo = this.writeinfo
      const mapData = this.MapData
      const colorPathFucntion = this.colorPath
      const setClikedFucntion = this.setCliked
      const colorLabelPathFucntion = this.colorLabelPath
      if (
        mapData[p.properties.CODE_KATO].id == this.$store.getters.region &&
        !this.default
      ) {
        this.path.attr('fill', '#dddddd').attr('clicked', false)
        this.label.classed('Clicked', false)
        this.$store.dispatch('updateRegion', null)
        writeinfo()
        return
      }
      const color = '#0071E3'
      this.path
        .attr('fill', (d) => colorPathFucntion(d, p, color))
        .attr('clicked', (d) => setClikedFucntion(d, p))
      this.label.classed('Clicked', (d) => colorLabelPathFucntion(d, p))
      this.$store.dispatch('updateRegion', mapData[p.properties.CODE_KATO].id)
      this.info.selectAll('*').remove()
      this.info
        .append('tspan')
        .attr('class', 'infoLabel')
        .text(function (d) {
          return 'Kursiv-CPI ' + graphPeriodType
        })
        .attr('dy', (d) => {
          return 20
        })
        .attr('pointer-events', 'none')
      this.info
        .append('tspan')
        .attr('class', 'infoLabel')
        .attr('dy', 24)
        .attr('x', (d) => {
          return this.width - 300
        })
        .text(function (d) {
          if (p.properties.TYPE_2 == 'Rayon')
            return 'по ' + p.properties.NAME_1 + ' '
          return 'по области ' + p.properties.NAME_1 + ' '
        })
      this.info
        .append('tspan')
        .attr('class', 'infoValue')
        .text(mapData[p.properties.CODE_KATO].cpi + '%')
    },
    setCliked(current, clicked, color) {
      if (current.properties.CODE_KATO == clicked.properties.CODE_KATO)
        return true
      return false
    },
    colorPath(current, clicked, color) {
      if (current.properties.CODE_KATO == clicked.properties.CODE_KATO)
        return color
      if (current.properties.TYPE_2 == 'Rayon') return '#333333'
      return '#dddddd'
    },
    colorLabelPath(current, clicked) {
      if (current.properties.CODE_KATO == clicked.properties.CODE_KATO)
        return true
    },
    labelTransform(d) {
      return d.properties.NAME_1
    },
    cpiTransform(d) {
      var cpi = this.MapData[d.properties.CODE_KATO].cpi
      return cpi + '%'
    },
    boxWidth(d) {
      var span = document.createElement('span')
      span.style.display = 'inline'
      span.style.visibility = 'hidden'
      span.style.padding = '0px'
      span.style.fontSize = 16 + 'px'
      span.style.font = 'times new roman'
      document.body.appendChild(span)
      var result = this.MapData[d.properties.CODE_KATO].report_price
      span.innerHTML = result
      return span.offsetWidth
    },
    findAndClickBasketByBasketId(basketId) {
      if (this.path._groups[0][0]) {
        const mapData = this.MapData
        const element = this.path._groups[0].find(
          (element) =>
            mapData[element.__data__.properties.CODE_KATO].id ===
            this.$store.getters.region
        )
        this.default = true
        this.clicked(false, element.__data__)
      }
    },
    color: d3.scaleOrdinal(['#dddddd']),
  },
  watch: {
    MapData(newValue, oldValue) {
      d3.json('map.json').then((json) => {
        this.geojson = json
        this.calculatePath()
        this.findAndClickBasketByBasketId()
      })
    },
    MapInfo(newValue, oldValue) {
      if (newValue != oldValue && !this.default) {
        this.writeinfo()
      }
      this.default = false
    },
  },
}
</script>
