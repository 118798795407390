import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {NewsService} from '@/types/NewsService'

export default class NewsApiService extends BaseApiService {
  async getNewsService(): Promise<BaseData<NewsService>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<NewsService>>(
        '/news'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
