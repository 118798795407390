import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {MainInfoData} from '@/types/MainInfoData'
import {MethodologyInfoData} from '@/types/MethodologyInfoData'

export default class MethodologyInfoApiService extends BaseApiService {
  async getData(): Promise<BaseData<MethodologyInfoData>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<MethodologyInfoData>
      >('methodology_info_data')
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
