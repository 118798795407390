import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {LinearCurrencyPoint} from '@/types/LinearCurrencyPoint'
import {CurrencyPoint} from '@/types/CurrencyPoint'
import {ReportDates} from '@/types/ReportDates'
import {Currency} from '@/types/Currency'
import useDateFormats from '@/uses/useDateFormats'
import {LinearChartResponseData} from '@/types/LinearChartResponseData'
const {formatDateToApi} = useDateFormats()

export default class CurrencyApiService extends BaseApiService {
  async getBasketsPriceChangeService(
    reportDates = new ReportDates()
  ): Promise<BaseData<LinearCurrencyPoint>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<LinearCurrencyPoint>
      >(`currency_rate_list`, {
        params: {
          currency_id: 'bf57bd0f-2a7e-4cfd-bd90-0a736f3eaebd',
          from_date: formatDateToApi(reportDates.report_date_begin),
          to_date: formatDateToApi(reportDates.report_date_end),
        },
      })
      //console.log(data.data);
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getCurrency(): Promise<BaseData<Currency>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<Currency>>(
        'currency'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getCurrencyPriceChangeService(
    currency: Currency,
    graphType = 1,
    reportDates = new ReportDates()
  ): Promise<LinearChartResponseData<CurrencyPoint>> {
    try {
      const {data} = await this.axiosInstance.get<
        LinearChartResponseData<CurrencyPoint>
      >(`report/get-currency-graphs/${graphType}`, {
        params: {
          base_date_begin: formatDateToApi(reportDates.base_date_begin),
          base_date_end: formatDateToApi(reportDates.base_date_end),
          report_date_begin: formatDateToApi(reportDates.report_date_begin),
          report_date_end: formatDateToApi(reportDates.report_date_end),
          currency_id: currency.id,
        },
      })
      return {error: null, data: data.data, meta: data.meta}
    } catch (error) {
      return {error: error, data: null, meta: null}
    }
  }
}
