import BaseApiService from '@/services/BaseApiService'
import {BaseData} from '@/types/BaseData'
import {ProductService} from '@/types/ProductService'
import {CustomBasketRequest} from '@/types/CustomBasketRequest'
import {BasketItem} from '@/types/BasketItem'
import {AxiosError} from 'axios'
import {BaseApiError} from '@/types/errors/BaseApiError'
import {PageableData} from '@/types/PageableData'
import {PageableResponseData} from '@/types/PageableResponseData'
import {CustomBasket} from '@/types/CustomBasket'
import {Basket} from '@/types/Basket'

export class ProductServiceApiService extends BaseApiService {
  async getList(
    page: number,
    search?: string
  ): Promise<PageableData<ProductService>> {
    try {
      const {data} = await this.axiosInstance.get<
        PageableResponseData<ProductService>
      >(`/food_product_service_list`, {
        params: {
          page,
          search,
        },
      })

      return {error: null, data: data.data, meta: data.meta}
    } catch (error) {
      return {error: error, data: null, meta: null}
    }
  }
  async add(basket: CustomBasketRequest): Promise<BaseData<CustomBasket>> {
    try {
      const {data} = await this.axiosInstance.post<BaseData<CustomBasket>>(
        `/custom_basket`,
        basket
      )
      return {error: null, data: data.data}
    } catch (error) {
      if (error instanceof AxiosError)
        return {error: error.response?.data, data: null}
      return {error: error, data: null}
    }
  }
  async update(
    basket: CustomBasketRequest,
    basketId: string
  ): Promise<BaseData<CustomBasket>> {
    try {
      const {data} = await this.axiosInstance.put<BaseData<CustomBasket>>(
        `/custom_basket/${basketId}`,
        basket
      )
      return {error: null, data: data.data}
    } catch (error) {
      if (error instanceof AxiosError)
        return {error: error.response?.data, data: null}
      return {error: error, data: null}
    }
  }
  async addBasketItems(
    basket_id: string,
    items: object
  ): Promise<BaseData<BasketItem>> {
    try {
      const {data} = await this.axiosInstance.post<BaseData<BasketItem>>(
        `custom_basket/${basket_id}/items`,
        {
          items: items,
        }
      )

      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getBasketsService(
    user_guide: string,
    basket_id: string
  ): Promise<BaseData<Basket[]>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<Basket[]>>(
        '/custom_basket_report/getBasketsWeight',
        {
          params: {
            custom_user_id: user_guide,
            basket_id: basket_id,
          },
        }
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getCustomBasketItems(
    basket_id: string
  ): Promise<BaseData<BasketItem[]>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<BasketItem[]>>(
        `/custom_basket/${basket_id}/items`,
        {}
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async deleteCustomBasketItem(
    basket_id: string,
    item_id: string
  ): Promise<BaseData<BasketItem[]>> {
    try {
      const {data} = await this.axiosInstance.delete<BaseData<BasketItem[]>>(
        `/custom_basket/${basket_id}/items/${item_id}/remove-item`,
        {}
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
