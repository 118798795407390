import BaseApiService from '@/services/BaseApiService'
import {Basket} from '@/types/Basket'
import {BaseData} from '@/types/BaseData'
import {ReportDates} from '@/types/ReportDates'
import {LinearPoint} from '@/types/LinearPoint'
import {BasketRequest} from '@/types/BasketRequest'
import {TopReportRequest} from '@/types/TopReportRequest'
import {ChartProductService} from '@/types/ChartProductService'
import {LinearChartResponseData} from '@/types/LinearChartResponseData'
import {Setting} from '@/types/Setting'
import useDateFormats from '@/uses/useDateFormats'
const {formatDateToApi} = useDateFormats()

export default class ReportApiService extends BaseApiService {
  async getTopProductServices(
    category: string,
    risen = 'desc'
  ): Promise<BaseData<ChartProductService>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<ChartProductService>
      >(`/report/diff-category/${category}/${risen}`)
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getTopProductServicesByBasketId(
    basket: BasketRequest,
    topReportRequest: TopReportRequest,
    region: null
  ): Promise<BaseData<ChartProductService>> {
    try {
      const {data} = await this.axiosInstance.get<
        BaseData<ChartProductService>
      >(`/report/top-low-from-basket/${basket.id}`, {
        params: {
          report_date_begin: formatDateToApi(
            topReportRequest.report_date_begin
          ),
          report_date_end: formatDateToApi(topReportRequest.report_date_end),
          risen: topReportRequest.risen,
          category_id: basket.category_id,
          region_id: region,
        },
      })
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getBasketsService(): Promise<BaseData<Basket[]>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<Basket[]>>(
        '/report/getBasketsWeight'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }

  async getBasketsPriceChangeService(
    basket: BasketRequest,
    graphType = 1,
    reportDates = new ReportDates(),
    region = null
  ): Promise<LinearChartResponseData<LinearPoint>> {
    try {
      const {data} = await this.axiosInstance.get<
        LinearChartResponseData<LinearPoint>
      >(`report/get-cpi-graphs/${basket.id}/${graphType}`, {
        params: {
          base_date_begin: formatDateToApi(reportDates.base_date_begin),
          base_date_end: formatDateToApi(reportDates.base_date_end),
          report_date_begin: formatDateToApi(reportDates.report_date_begin),
          report_date_end: formatDateToApi(reportDates.report_date_end),
          category_id: basket.product_and_service_id
            ? null
            : basket.category_id,
          product_and_service_id: basket.product_and_service_id,
          region_id: region,
        },
      })
      return {error: null, data: data.data, meta: data.meta}
    } catch (error) {
      return {error: error, data: null, meta: null}
    }
  }

  // async getLinear(): Promise<BaseData<LinearGraph>> {
  //   try {
  //     const { data } = await this.axiosInstance.get<BaseData<LinearGraph>>(
  //       "/report/getAllCostChart"
  //     );
  //     return { error: null, data: data.data };
  //   } catch (error) {
  //     return { error: error, data: null };
  //   }
  // }

  async getDefaultCalculationInfo(): Promise<BaseData<Setting>> {
    try {
      const {data} = await this.axiosInstance.get<BaseData<Setting>>(
        '/report/get-default-calculation-type'
      )
      return {error: null, data: data.data}
    } catch (error) {
      return {error: error, data: null}
    }
  }
}
