/* eslint-disable */
export const SunburstData = [
    {
      id: "daa1b9bc-4d49-4600-aa30-a94323f7ab1a",
      name: "Продовольственные товары",
      is_default: false,
      children: [
        {
          id: "69bff402-c52e-4ae1-b44c-a6f19bd5b932",
          name: "Услуги общественного питания и гостиницы",
          value: "0.64087",
          
          children: [
            {
              id: "f45dc2ba-d5c4-4d1b-a6ac-a88c690a190d",
              name: "Сок и напитки",
              
              value: 100
            }
          ]
        },
        {
          id: "971cc2b4-f302-11ec-b939-0242ac120002",
          name: "Масла, жиры и яйца",
          value: "7.23476",
          
          children: [
            {
              id: "7250a393-d3dc-4312-9f03-5cd77a007c21",
              name: "Масло оливковое",
              
              value: 1.0725995057196092
            },
            {
              id: "71656d3b-7770-4008-870e-98329c016282",
              name: "Маргарин",
              
              value: 2.318390658432346
            },
            {
              id: "d212500a-1775-47ef-9740-897c9f201d3a",
              name: "Масло растительно-сливочное",
              
              value: 3.8409014258938794
            },
            {
              id: "6d29c5ce-60ae-491d-97c9-9c073510c16f",
              name: "Яйца 1 категории",
              
              value: 25.328690931005315
            },
            {
              id: "e5e8ae16-c43c-4a56-86af-c588f6b1e136",
              name: "Масло сливочное несоленое",
              
              value: 25.328690931005315
            },
            {
              id: "f9b628b9-c6d4-463b-ae0b-473bcb6bac94",
              name: "Масло подсолнечное",
              
              value: 42.11072654794354
            }
          ]
        },
        {
          id: "971cc386-f302-11ec-b939-0242ac120002",
          name: "Фрукты",
          value: "9.11981",
          
          children: [
            {
              id: "f6af50b5-fbc2-4e49-a2a4-2f856334c969",
              name: "Сухофрукты для компота",
              
              value: 1.1528748954199703
            },
            {
              id: "3176dbde-4689-48be-9e39-93fcdb9f49b1",
              name: "Семена подсолнечника",
              
              value: 2.0312923185899705
            },
            {
              id: "ae1511ea-db40-427d-9400-1db2ee2c09cf",
              name: "Апельсины",
              
              value: 6.533030841651307
            },
            {
              id: "9df3a4e4-6d98-47ef-90d1-8ba858e1090e",
              name: "Лимоны",
              
              value: 2.8273615349442593
            },
            {
              id: "0d4a291d-7e2a-4bfa-8bf3-f3f3d785d800",
              name: "Ягоды замороженные",
              
              value: 3.6234307512985473
            },
            {
              id: "c700d4ee-9406-4511-a86c-66554bba6aae",
              name: "Груши",
              
              value: 4.501738523061336
            },
            {
              id: "4950ad08-4a7a-488f-ae80-c6190e13fd3d",
              name: "Изюм",
              
              value: 4.529261026271381
            },
            {
              id: "d5ac88ae-9b7f-4e3f-ae51-69e708333b59",
              name: "Курага",
              
              value: 4.529261026271381
            },
            {
              id: "09900daa-0e5f-4e81-8f02-bfdc808e029b",
              name: "Орехи (фундук, миндаль, кешью, грецкий орех)",
              
              value: 3.815540016732805
            },
            {
              id: "7aff91e2-bd52-471a-8c24-9ba4e1b36cc4",
              name: "Арахис (орех земляной)",
              
              value: 3.815540016732805
            },
            {
              id: "d005f4ec-a70e-4efd-829b-1d94e1df6345",
              name: "Финики",
              
              value: 3.815540016732805
            },
            {
              id: "04a34cdc-1f44-45b5-9b7f-699b2667a070",
              name: "Виноград",
              
              value: 9.580024145239868
            },
            {
              id: "e282c704-ab73-49ea-a183-8d0ee7bcdd1a",
              name: "Бананы",
              
              value: 11.309336488369825
            },
            {
              id: "176dc9d6-2788-40c2-9881-e55b3f667162",
              name: "Киви",
              
              value: 11.309336488369825
            },
            {
              id: "4b7f0cf0-d245-4a65-b516-4bfdfe760149",
              name: "Фрукты консервированные",
              
              value: 0.21963176864430292
            },
            {
              id: "0330ee81-6370-44fc-947e-b83be60557d5",
              name: "Яблоки",
              
              value: 26.40680014166962
            }
          ]
        },
        {
          id: "971cc610-f302-11ec-b939-0242ac120002",
          name: "Продукты питания, не отнесенные к другим категориям",
          value: "2.34567",
          
          children: [
            {
              id: "03581e2b-41fd-4acb-b728-9af5e6ba9b54",
              name: "Уксус, уксусная эссенция",
              
              value: 1.1740781951425392
            },
            {
              id: "36dc8667-73b8-4f96-822d-590c678a9504",
              name: "Перец красный молотый",
              
              value: 1.494242583142556
            },
            {
              id: "71984c60-268b-4945-9434-987a5fc6eefa",
              name: "Концентраты супов",
              
              value: 1.707401296857614
            },
            {
              id: "d35ddd6f-f56d-4485-ba15-d2241bb32e86",
              name: "Сода пищевая ",
              
              value: 1.9209863280001023
            },
            {
              id: "a8f5aaa0-6382-4ad7-93d2-80c94edee947",
              name: "Дрожжи",
              
              value: 6.083123372000324
            },
            {
              id: "69ecb873-9860-46be-98c9-9e4642c58ac7",
              name: "Готовые приправы и соусы",
              
              value: 6.296708403142811
            },
            {
              id: "af436ac9-1ea5-4386-be63-b37573134a8a",
              name: "Перец черный молотый",
              
              value: 7.150622210285333
            },
            {
              id: "7aad141a-3c9a-4fd8-85bc-7367c445afef",
              name: "Томатная паста",
              
              value: 20.811111537428534
            },
            {
              id: "7faa136b-804f-4b80-a573-33dbc8841057",
              name: "Майонез",
              
              value: 25.29341296942878
            },
            {
              id: "11c59306-3b6a-4205-b921-fccaf3140e5d",
              name: "Соль, кроме экстра",
              
              value: 8.431279762285403
            },
            {
              id: "33c1c9ac-4abd-4431-9296-1667023b0036",
              name: "Соус томатный, кетчуп",
              
              value: 16.541968819143356
            },
            {
              id: "7df05ad0-917d-4c5a-9176-76f8d8492855",
              name: "Другие пряности",
              
              value: 3.0950645231426415
            }
          ]
        },
        {
          id: "971cbfe4-f302-11ec-b939-0242ac120002",
          name: "Мясо",
          value: "21.39389",
          
          children: [
            {
              id: "8cadcecb-3141-4e1c-a4f0-d42c245c10e6",
              name: "Мясокопченности свиные",
              
              value: 0.2340387839705636
            },
            {
              id: "bac128a5-21d3-445d-8381-0c9e3b6f76c6",
              name: "Колбаса сырокопченая",
              
              value: 0.2925134232250423
            },
            {
              id: "a6c27ec9-dc3e-4056-a8e0-94d2b7a4c29b",
              name: "Жая",
              
              value: 0.35103480479707055
            },
            {
              id: "6c40a988-81c1-44a6-a162-396f418556be",
              name: "Котлеты из мяса",
              
              value: 0.3861382852767777
            },
            {
              id: "0cfe12fc-d6e5-40e1-9a24-bf31c626908a",
              name: "Сало (шпик)",
              
              value: 0.5850735887676342
            },
            {
              id: "c945cd1c-6a6b-4a2a-93c4-2c21384659bc",
              name: "Мясокопченности",
              
              value: 0.6669661291144342
            },
            {
              id: "9c937757-aa2d-46ee-a951-816285a95ac3",
              name: "Свинина с костями",
              
              value: 0.8191123727381978
            },
            {
              id: "06de4996-87c2-489f-b2ff-f1f64dda28d7",
              name: "Мясные полyфабpикаты мелкокусковые",
              
              value: 0.8775870119926764
            },
            {
              id: "122e254f-3799-4ea6-b307-e859fd385ebd",
              name: "Консервы мясные тушеные",
              
              value: 0.8893193336976118
            },
            {
              id: "f6e6d0e0-a7c5-4d4c-87ad-9d89532e7ea5",
              name: "Печень говяжья",
              
              value: 2.0945232493950376
            },
            {
              id: "1b4dc18e-a509-4bf6-bc0c-aa5eef699d62",
              name: "Казы",
              
              value: 2.164776952672001
            },
            {
              id: "2556b596-0152-459a-bfac-e98cc6f59715",
              name: "Сосиски, сардельки",
              
              value: 3.709330093779112
            },
            {
              id: "1b683942-c145-48e0-a08c-8e307b276970",
              name: "Свинина бескостная",
              
              value: 1.3456645799338034
            },
            {
              id: "d74e1da9-7ab4-48a9-bd18-5c4674b91fa5",
              name: "Части курицы (бедро, голень, крылышки)",
              
              value: 1.474392922465246
            },
            {
              id: "10762d62-1801-497a-97a8-4a83e2bdb2c6",
              name: "Грудки кур",
              
              value: 1.474392922465246
            },
            {
              id: "684c07fc-b43e-420e-91db-2d719949687d",
              name: "Мясокопченности из других видов мяса",
              
              value: 0.2457243633579494
            },
            {
              id: "a040966a-6ef1-4be8-8d0d-38a17c85fe03",
              name: "Свинина ребра",
              
              value: 1.474392922465246
            },
            {
              id: "127d32c3-1bea-4dd4-b023-0456961dcbc6",
              name: "Баранина - задняя часть с костью",
              
              value: 3.25298484754292
            },
            {
              id: "945eed84-59c6-47f6-8c9f-613d115aaf76",
              name: "Мясной фарш",
              
              value: 4.458235505557895
            },
            {
              id: "0370161c-9c77-4a38-bff2-39529e02020e",
              name: "Баранина с костями",
              
              value: 5.5347578210414285
            },
            {
              id: "97c67f3b-ac3c-42a8-aeda-79eb61eea1b5",
              name: "Куры",
              
              value: 5.710275223439964
            },
            {
              id: "682e43e2-e67a-44d4-8593-89a1500e116c",
              name: "Колбаса вареная",
              
              value: 6.786797538923497
            },
            {
              id: "c33ca026-b64e-4e18-882b-ac422cd42cf1",
              name: "Окорочка куриные",
              
              value: 7.453763668037931
            },
            {
              id: "e763233e-da55-41d5-a3e6-92fd94cbf18b",
              name: "Колбаса полукопченая",
              
              value: 7.746323833580523
            },
            {
              id: "2d40b356-a359-4336-94c4-86f6a2d0748c",
              name: "Язык говяжий",
              
              value: 0.09362486205173534
            },
            {
              id: "5fd11f14-b8c9-4531-b187-6b637b7e3401",
              name: "Конина с костями",
              
              value: 9.700433161056733
            },
            {
              id: "b57dc275-f8fc-4d84-a3b5-4830ce45c5bc",
              name: "Говядина бескостная",
              
              value: 14.661802972717913
            },
            {
              id: "31606cef-0e99-4354-91bc-521b1b7e42c5",
              name: "Говядина с костями",
              
              value: 15.516018825935816
            }
          ]
        },
        {
          id: "971cc0d4-f302-11ec-b939-0242ac120002",
          name: "Рыба и морепродукты",
          value: "2.37321",
          
          children: [
            {
              id: "63143067-09c4-4637-a949-b6574d68d931",
              name: "Консеpвы рыбные деликатесные",
              
              value: 1.3711386687229534
            },
            {
              id: "05666aac-2ebb-41b9-83ad-9dbff6ecfefd",
              name: "Морепродукты",
              
              value: 1.6875876976753006
            },
            {
              id: "46ad3356-fded-4adf-b46e-947c88d44947",
              name: "Икра красная",
              
              value: 1.7933516208005194
            },
            {
              id: "0f2a2532-ee30-4e24-afcc-e4c3932665f3",
              name: "Рыба соленая, деликатесная",
              
              value: 4.852499357410427
            },
            {
              id: "80e65c42-633e-4814-ae5a-f5d3666ba02f",
              name: "Скумбрия холодного копчения",
              
              value: 4.852499357410427
            },
            {
              id: "655371ee-46b1-4b12-9dca-feb654f39a47",
              name: "Шпроты в масле",
              
              value: 7.805883170895116
            },
            {
              id: "7256daee-eeaa-4987-a513-c3cc15b5dbd7",
              name: "Сельдь соленая",
              
              value: 21.730061815010053
            },
            {
              id: "64a2fb45-3ca0-4b4d-a949-f6cbb2c62ee6",
              name: "Рыба мороженная",
              
              value: 8.649887704838594
            },
            {
              id: "558fa941-9ed9-4f68-82b8-7c2b41dcdbbd",
              name: "Рыба свежая или охлажденная",
              
              value: 44.30370679375192
            },
            {
              id: "71d9e4e7-e765-475f-a141-435529b721df",
              name: "Креветки замороженные очищенные",
              
              value: 2.95338381348469
            }
          ]
        },
        {
          id: "971cc890-f302-11ec-b939-0242ac120002",
          name: "Безалкогольные напитки",
          value: "5.17198",
          
          children: [
            {
              id: "6f8d0609-99a6-4efb-84fc-d7f34420df51",
              name: "Соки овощные",
              
              value: 0.6291594321710448
            },
            {
              id: "658c048c-765a-4fc9-98c1-8e3fd3c71050",
              name: "Полуфабрикаты для изготовления напитков на основе какао",
              
              value: 1.209981477113214
            },
            {
              id: "6e8b3191-df52-4cdc-95e8-de90c2a6cd18",
              name: "Кофе в зернах, молотый",
              
              value: 1.3068496011198807
            },
            {
              id: "7ef92c41-6652-4d7f-9bd0-952e5d50ff5a",
              name: "Питьевая вода, продаваемая в емкостях",
              
              value: 1.88767164606205
            },
            {
              id: "df71317e-1218-4daf-99e1-c254bba0decb",
              name: "Чай зеленый",
              
              value: 2.9526409614886373
            },
            {
              id: "e5ff26d8-b66a-4da7-abb5-8091bd9d9746",
              name: "Напитки негазированные",
              
              value: 3.43659488242414
            },
            {
              id: "91aaaad5-d1ea-4925-b19e-56a44a388962",
              name: "Напитки газированные",
              
              value: 10.019373624801334
            },
            {
              id: "3a10d442-ccba-4ff7-8ba0-af467d3c1b42",
              name: "Кофе растворимый",
              
              value: 11.76183975962784
            },
            {
              id: "510e25ed-f593-4d3e-a296-b3ee1e1055f8",
              name: "Минеральная вода",
              
              value: 4.98551811878623
            },
            {
              id: "ca05e14e-35ab-49e2-ae60-91a46e3d4172",
              name: "Соки фруктовые",
              
              value: 16.699020491185195
            },
            {
              id: "43eff64f-c988-4e0f-9eda-27a056fcd929",
              name: "Чай черный байховый",
              
              value: 45.111350005220444
            }
          ]
        },
        {
          id: "971cc534-f302-11ec-b939-0242ac120002",
          name: "Сахар, джем, мед, шоколад, кондитерские изделия",
          value: "6.68902",
          
          children: [
            {
              id: "e4bab238-ef10-4695-b540-4e1ac02f151d",
              name: "Сахар-рафинад",
              
              value: 1.3099078788821084
            },
            {
              id: "dce17c45-65b2-404c-947e-4d0765fe97ea",
              name: "Ирис",
              
              value: 1.6841032019638154
            },
            {
              id: "92060ea6-e896-42de-b16c-372cdec157b9",
              name: "Мармелад",
              
              value: 2.4326433468579856
            },
            {
              id: "074f51db-7b54-4ecf-9f48-b26ba4ffbe34",
              name: "Мороженое",
              
              value: 7.447578270060487
            },
            {
              id: "46387344-f66c-41e1-bc67-ae3912e2d0c1",
              name: "Шоколад",
              
              value: 7.634750680966718
            },
            {
              id: "d72a07e3-66a3-40aa-8ebf-70d64336d967",
              name: "Каpамель фруктово-ягодная",
              
              value: 8.08384486815707
            },
            {
              id: "5b6501db-607e-49b7-9c7c-2c0f5ca25343",
              name: "Мед",
              
              value: 8.869759695740184
            },
            {
              id: "0dd4a197-9a30-4d6b-a2a2-1f5a1e144cc6",
              name: "Конфеты неглазированные шоколадом",
              
              value: 3.255932857130043
            },
            {
              id: "88cb6079-5415-454e-95d0-a36c2c03b416",
              name: "Варенье, джем и повидло",
              
              value: 3.555378814833862
            },
            {
              id: "e665d68b-f495-462a-9e6f-e73a6874cffe",
              name: "Халва",
              
              value: 5.164732651419789
            },
            {
              id: "3f96f72c-6ffc-44a8-b899-fab397e96c93",
              name: "Конфеты глазированные шоколадом",
              
              value: 12.911756879184097
            },
            {
              id: "adf9eacf-8448-44fb-984d-36845b72cfa4",
              name: "Жевательная резинка",
              
              value: 0.5987424166768823
            },
            {
              id: "1480a130-73a9-4e4a-b82e-002b536abf28",
              name: "Зефир",
              
              value: 1.6841032019638154
            },
            {
              id: "a3141684-d596-4c89-9307-c28e4ca63c45",
              name: "Сахар-песок",
              
              value: 35.36676523616314
            }
          ]
        },
        {
          id: "971cc96c-f302-11ec-b939-0242ac120002",
          name: "Алкогольные напитки, табачные изделия",
          value: "4.38842",
          
          children: [
            {
              id: "c940f56b-d07f-458e-9fda-3a49237f5208",
              name: "Шампанское, игристые вина",
              
              value: 3.0803797266442134
            },
            {
              id: "e213df6e-778e-469f-b164-3698e8a455f7",
              name: "Коньяки ординарные, марочные",
              
              value: 3.7079404432574825
            },
            {
              id: "e3e6ead0-7784-4ddc-aa75-a677f9359b11",
              name: "Вина виноградные, столовые сухие",
              
              value: 4.107172968858952
            },
            {
              id: "eb9d556d-8335-4a25-a7dd-49b8bb02f3e5",
              name: "Вина виноградные крепленые",
              
              value: 4.335501159870751
            },
            {
              id: "484405d0-6c44-4a66-8e7f-4e87a99de5ca",
              name: "Пиво с содержанием алкоголя",
              
              value: 16.486115731857936
            },
            {
              id: "20c11f5c-9604-4eec-9c98-99f6c577f001",
              name: "Водка",
              
              value: 25.727254911790574
            },
            {
              id: "46e9bea5-6245-4811-91c6-63eeefee59c4",
              name: "Сигареты с фильтром",
              
              value: 42.555635057720096
            }
          ]
        },
        {
          id: "971cc458-f302-11ec-b939-0242ac120002",
          name: "Овощи",
          value: "8.32122",
          
          children: [
            {
              id: "7435f577-2219-4052-b7c9-eee7db93df8b",
              name: "Консеpвы закусочные, обеденные",
              
              value: 0.33096108503320426
            },
            {
              id: "13e018a1-84b1-4b2f-ac06-1aea792cfdb8",
              name: "Грибы соленые и маринованные",
              
              value: 0.45125594564258603
            },
            {
              id: "ef23e3fc-c292-4271-994e-b443a6f2ce67",
              name: "Огурцы соленые и маринованные",
              
              value: 1.1732654586707236
            },
            {
              id: "87c3e115-d4b5-4583-9323-6fedfdb3e8a7",
              name: "Картофельные чипсы",
              
              value: 1.504226543703928
            },
            {
              id: "954fb70e-5363-4606-bc50-02f0a12bfcc7",
              name: "Кукуруза консервированная",
              
              value: 1.6245214043133096
            },
            {
              id: "ecc13220-3cff-4ff5-830e-10d8bc4c8415",
              name: "Зеленый горошек консервированный",
              
              value: 1.6245214043133096
            },
            {
              id: "cb36a246-94e0-4180-a8ab-03a523b2fb7b",
              name: "Фасоль",
              
              value: 1.6245214043133096
            },
            {
              id: "bbd03afd-1db6-4fc6-be41-4760e8d57358",
              name: "Чеснок",
              
              value: 1.8652313002179968
            },
            {
              id: "e0f0e3f7-096d-492e-b5bf-c5308ac03456",
              name: "Горох",
              
              value: 1.9855261608273789
            },
            {
              id: "77df7b39-6052-4b75-ac76-e7bdc9460900",
              name: "Морковь",
              
              value: 7.160007787319647
            },
            {
              id: "a88025da-a6a6-4f87-9e93-c93a6b6ad7e5",
              name: "Капуста белокочанная",
              
              value: 8.51389579893333
            },
            {
              id: "8c5cb6c8-e5c5-495b-88a9-5a9410e00a46",
              name: "Овощи натуральные, консервированные, маринованные",
              
              value: 2.8880380521125506
            },
            {
              id: "b434ab88-1439-4859-a10e-295876989934",
              name: "Свекла",
              
              value: 3.128747948017238
            },
            {
              id: "acb644ef-eff3-453d-b7d4-0d879082e656",
              name: "Цветная капуста ",
              
              value: 3.128747948017238
            },
            {
              id: "56ef155d-3133-4ad6-9542-2e0f9624aa94",
              name: "Перец сладкий",
              
              value: 4.18171854607858
            },
            {
              id: "8683202d-ea87-4039-b2e2-65f91808bfb3",
              name: "Лук репчатый",
              
              value: 11.25147514426971
            },
            {
              id: "3dcf12b7-6627-4fa0-81b1-481036d5337a",
              name: "Огурцы",
              
              value: 11.97360483198377
            },
            {
              id: "9e403524-4856-4235-a22c-edfac8e66e1b",
              name: "Овощи натуральные замороженные",
              
              value: 0.15045870677616985
            },
            {
              id: "38e770e8-3082-47be-a6c8-87c978abee95",
              name: "Помидоры",
              
              value: 14.801435366448668
            },
            {
              id: "2a49bd7e-f1fa-4c2b-b4f8-7f38df7a7d81",
              name: "Картофель",
              
              value: 20.637839163007346
            }
          ]
        },
        {
          id: "971cc1ce-f302-11ec-b939-0242ac120002",
          name: "Молочные продукты",
          value: "11.38538",
          
          children: [
            {
              id: "98cb5b3d-4d23-4472-9d4e-20ffe418c244",
              name: "Сухие молочные смеси для детей",
              
              value: 0.35176691511394437
            },
            {
              id: "4963b21e-556b-4b05-87f0-5b6a0b6167da",
              name: "Молоко концентрированное без сахара",
              
              value: 0.6156140594341164
            },
            {
              id: "153c0298-53bb-4124-b1fa-45f76b3514b3",
              name: "Сыры плавленые",
              
              value: 4.749336429701952
            },
            {
              id: "11a99da4-7608-4e30-bdd2-4f494e6a91c8",
              name: "Йогурт",
              
              value: 5.452958091868695
            },
            {
              id: "def8f034-9871-4a95-9e53-4a7f01085b63",
              name: "Молоко пастеризованное 2,5% жирности",
              
              value: 5.562923679315052
            },
            {
              id: "5058559d-61e2-4024-a259-d4b42b840333",
              name: "Молоко пастеризованное 3,2-4% жирности",
              
              value: 5.562923679315052
            },
            {
              id: "7bf5aa1a-17cd-448e-a8e5-69098b7f0842",
              name: "Кефир 2,5% жирности",
              
              value: 6.310461310909255
            },
            {
              id: "2479cb77-8a4b-4d66-ac17-a25786e2d345",
              name: "Молоко сгущенное с сахаром",
              
              value: 2.0228573837676036
            },
            {
              id: "e9b1c189-2b88-48f2-9d9b-a2c791fa7383",
              name: "Молоко сырое",
              
              value: 6.882159400915913
            },
            {
              id: "17a78f0d-4f38-4578-a34f-a841711624f1",
              name: "Ряженка",
              
              value: 2.5285936876942183
            },
            {
              id: "555bd9a5-1d1a-494e-b19f-b52cacb96ccc",
              name: "Курт",
              
              value: 3.166253563780919
            },
            {
              id: "90d83bd8-6dd1-421b-b762-f4a616fa0a50",
              name: "Твороженая масса, сырок",
              
              value: 3.166253563780919
            },
            {
              id: "6999c6b4-6b0c-4e9c-a406-dc35687e3ee2",
              name: "Кумыс",
              
              value: 3.0562879763345623
            },
            {
              id: "70601a42-5823-4847-a10a-1199b982b0f4",
              name: "Сыры рассольные",
              
              value: 3.0782459610482915
            },
            {
              id: "7ab7061d-8f76-4fd5-971f-de28156091a6",
              name: "Сливки",
              
              value: 0.461732502560301
            },
            {
              id: "baa34d26-8671-4a90-bbe6-d18f0ce6a1be",
              name: "Творог 5-9% жирности",
              
              value: 10.18024870491806
            },
            {
              id: "3fc02fa4-3176-4b91-b289-47f7d412837d",
              name: "Сыры твердый",
              
              value: 10.576107253337174
            },
            {
              id: "c57e6841-4d6e-45d3-bca7-a56b8788dc0e",
              name: "Молоко ультрапастеризованное, стерилизованное",
              
              value: 12.357075477498336
            },
            {
              id: "cdb420ee-9f6e-4735-9b9c-b238dfc2ca57",
              name: "Сметана",
              
              value: 13.74227298517924
            },
            {
              id: "b50f72eb-dcb6-490c-82f6-6e5e77cfd02d",
              name: "Сухие сливки",
              
              value: 0.17592737352639967
            }
          ]
        },
        {
          id: "971cbd28-f302-11ec-b939-0242ac120002",
          name: "Хлебопродукты и крупяные изделия",
          value: "20.51767",
          
          children: [
            {
              id: "fc4a065f-662c-4d73-b02e-191820c6f743",
              name: "Каши быстрого приготовления (исключая каши для детского питания)",
              
              value: 0.195197602846717
            },
            {
              id: "7bec608d-f200-4e68-873e-27e32a52517c",
              name: "Хлопья из злаков (сухие завтраки)",
              
              value: 0.4148131829783792
            },
            {
              id: "b67db47d-db35-43b7-9905-a5cc99627736",
              name: "Тесто",
              
              value: 0.5368543309254902
            },
            {
              id: "b23529dd-d694-4731-9488-4605d608a583",
              name: "Пшено",
              
              value: 0.6832647176799316
            },
            {
              id: "45217a8d-f3a6-4ced-b339-6c93ed636a37",
              name: "Крупа кукурузня",
              
              value: 0.6832647176799316
            },
            {
              id: "30978abf-c43f-41a9-af69-758b307f845f",
              name: "Каши для детского питания",
              
              value: 0.8418597238380382
            },
            {
              id: "36d27bf3-1e04-455d-b40b-7e45af795364",
              name: "Рулеты, кексы",
              
              value: 0.8662777011229833
            },
            {
              id: "7da9a985-324c-451e-97ac-fea43ca4a6b9",
              name: "Крупа перловая",
              
              value: 0.8906956784079284
            },
            {
              id: "fed0698f-5a3e-453d-a144-c64a857b14c5",
              name: "Пряники",
              
              value: 2.464607336018174
            },
            {
              id: "d72be0df-bd1f-414f-803e-93a093c531b9",
              name: "Лапша",
              
              value: 2.696456274031116
            },
            {
              id: "ca2be420-2a36-41a2-a155-f1bced72b089",
              name: "Мука пшеничная первого сорта",
              
              value: 2.7208255128384464
            },
            {
              id: "63da1844-c4c3-44d2-90d8-c42bd8d71f71",
              name: "Вафли",
              
              value: 3.2942824404525473
            },
            {
              id: "abc997b4-9e73-4713-9d89-e1373a332c4b",
              name: "Булочки сдобные",
              
              value: 3.379720991711047
            },
            {
              id: "9811d2af-c4c8-4caf-9bcc-a5f6f12b7c95",
              name: "Пельмени, манты, хинкали",
              
              value: 3.5138980205842087
            },
            {
              id: "a2d91131-ce75-4ca2-a756-6b1a0c2a394e",
              name: "Вареники",
              
              value: 3.5138980205842087
            },
            {
              id: "e329f2d8-38cd-4267-81ed-013e009f5ce1",
              name: "Пирожное",
              
              value: 0.9882701105924796
            },
            {
              id: "e4014cad-403f-46e5-88f5-cc74b31cfe1e",
              name: "Крупа манная",
              
              value: 1.1224958779432557
            },
            {
              id: "a73a2325-ef63-404c-ad76-399a3d2ed796",
              name: "Крупа овсяная",
              
              value: 1.134680497346921
            },
            {
              id: "1167979c-0fda-4171-bebe-e8c631b41ecd",
              name: "Сушки, баранки",
              
              value: 1.1957010713204763
            },
            {
              id: "a4ea2393-8e49-48e2-a49a-5fd15f59c437",
              name: "Сухарики",
              
              value: 1.1957010713204763
            },
            {
              id: "dcfb7d37-8776-4eda-a0ef-0e133e5d9fb6",
              name: "Вермишель",
              
              value: 3.7579315780008162
            },
            {
              id: "1e8bdf27-b504-4a1d-a622-b02ec2146687",
              name: "Крупа гречневая",
              
              value: 3.818952151974372
            },
            {
              id: "de18c017-436a-417d-af03-9abf2fe02552",
              name: "Батон нарезной",
              
              value: 3.9043419647552575
            },
            {
              id: "57b855bc-d32b-49b8-8d58-6ccae0235ceb",
              name: "Хлеб ржаной и ржано-пшеничный",
              
              value: 3.9043419647552575
            },
            {
              id: "a9cfde18-6925-4d7e-ba38-bc6eb6a7e02c",
              name: "Хлеб пшеничный из муки высшего сорта",
              
              value: 3.9043419647552575
            },
            {
              id: "6ac40757-421a-4aa8-a0ff-e0cbef171e00",
              name: "Печенье сахарное",
              
              value: 3.9165265841589223
            },
            {
              id: "a817c8cd-3008-4a47-b7de-2df67afe4097",
              name: "Печенье сдобное",
              
              value: 4.062985709390979
            },
            {
              id: "780461a6-94d4-4c5f-88f2-3bcef8a807ae",
              name: "Торты",
              
              value: 4.19716273826414
            },
            {
              id: "0593f155-ed01-4fc1-a3d6-3eabe0b3d472",
              name: "Рожки",
              
              value: 5.514904957531727
            },
            {
              id: "8ed93fa4-947d-4a1b-81ef-118e4ffd9181",
              name: "Спагетти",
              
              value: 5.514904957531727
            },
            {
              id: "5b98091b-5ead-4699-aabd-5f576abbe05a",
              name: "Мука пшеничная высшего сорта",
              
              value: 5.929718140510106
            },
            {
              id: "966007fb-5c26-4804-b4cd-bb2eaa37d803",
              name: "Рис шлифованный, полированный",
              
              value: 9.37045970619471
            },
            {
              id: "be7c09f8-dbc5-457b-aaa1-3fb77722aae8",
              name: "Хлеб пшеничный из муки первого сорта",
              
              value: 9.870662701953975
            }
          ]
        }
      ],
      "basket_total_weight": 99.5819
    },
    {
      id: "e1891edf-fac6-48fb-bdd7-14a30402d200",
      name: "Социально значимые продовольственные товары",
      is_default: true,
      children: [
        {
          id: "971cbd28-f302-11ec-b939-0242ac120002",
          name: "Хлебопродукты и крупяные изделия",
          value: "24.04387",
          
          children: [
            {
              id: "ca2be420-2a36-41a2-a155-f1bced72b089",
              name: "Мука пшеничная первого сорта",
              
              value: 8.693941532706674
            },
            {
              id: "1e8bdf27-b504-4a1d-a622-b02ec2146687",
              name: "Крупа гречневая",
              
              value: 12.202736082003437
            },
            {
              id: "0593f155-ed01-4fc1-a3d6-3eabe0b3d472",
              name: "Рожки",
              
              value: 17.621830429128092
            },
            {
              id: "966007fb-5c26-4804-b4cd-bb2eaa37d803",
              name: "Рис шлифованный, полированный",
              
              value: 29.94151939766768
            },
            {
              id: "be7c09f8-dbc5-457b-aaa1-3fb77722aae8",
              name: "Хлеб пшеничный из муки первого сорта",
              
              value: 31.53997255849412
            }
          ]
        },
        {
          id: "971cbfe4-f302-11ec-b939-0242ac120002",
          name: "Мясо",
          value: "17.00413",
          
          children: [
            {
              id: "97c67f3b-ac3c-42a8-aeda-79eb61eea1b5",
              name: "Куры",
              
              value: 26.901876191254715
            },
            {
              id: "31606cef-0e99-4354-91bc-521b1b7e42c5",
              name: "Говядина с костями",
              
              value: 73.09812380874529
            }
          ]
        },
        {
          id: "971cc1ce-f302-11ec-b939-0242ac120002",
          name: "Молочные продукты",
          value: "9.40195",
          
          children: [
            {
              id: "def8f034-9871-4a95-9e53-4a7f01085b63",
              name: "Молоко пастеризованное 2,5% жирности",
              
              value: 25.224341758890443
            },
            {
              id: "7bf5aa1a-17cd-448e-a8e5-69098b7f0842",
              name: "Кефир 2,5% жирности",
              
              value: 28.614170464637656
            },
            {
              id: "baa34d26-8671-4a90-bbe6-d18f0ce6a1be",
              name: "Творог 5-9% жирности",
              
              value: 46.16148777647191
            }
          ]
        },
        {
          id: "971cc2b4-f302-11ec-b939-0242ac120002",
          name: "Масла, жиры и яйца",
          value: "25.13123",
          
          children: [
            {
              id: "6d29c5ce-60ae-491d-97c9-9c073510c16f",
              name: "Яйца 1 категории",
              
              value: 27.303239833466172
            },
            {
              id: "e5e8ae16-c43c-4a56-86af-c588f6b1e136",
              name: "Масло сливочное несоленое",
              
              value: 27.303239833466172
            },
            {
              id: "f9b628b9-c6d4-463b-ae0b-473bcb6bac94",
              name: "Масло подсолнечное",
              
              value: 45.39352033306767
            }
          ]
        },
        {
          id: "971cc458-f302-11ec-b939-0242ac120002",
          name: "Овощи",
          value: "14.82002",
          
          children: [
            {
              id: "77df7b39-6052-4b75-ac76-e7bdc9460900",
              name: "Морковь",
              
              value: 15.053758361999513
            },
            {
              id: "a88025da-a6a6-4f87-9e93-c93a6b6ad7e5",
              name: "Капуста белокочанная",
              
              value: 17.900043319779595
            },
            {
              id: "8683202d-ea87-4039-b2e2-65f91808bfb3",
              name: "Лук репчатый",
              
              value: 23.6559059974278
            },
            {
              id: "2a49bd7e-f1fa-4c2b-b4f8-7f38df7a7d81",
              name: "Картофель",
              
              value: 43.390292320793094
            }
          ]
        },
        {
          id: "971cc534-f302-11ec-b939-0242ac120002",
          name: "Сахар, джем, мед, шоколад, кондитерские изделия",
          value: "8.85827",
          
          children: [
            {
              id: "a3141684-d596-4c89-9307-c28e4ca63c45",
              name: "Сахар-песок",
              
              value: 100
            }
          ]
        },
        {
          id: "971cc610-f302-11ec-b939-0242ac120002",
          name: "Продукты питания, не отнесенные к другим категориям",
          value: "0.74053",
          
          children: [
            {
              id: "11c59306-3b6a-4205-b921-fccaf3140e5d",
              name: "Соль, кроме экстра",
              
              value: 100
            }
          ]
        }
      ],
      "basket_total_weight": 100
    },
    {
      id: "35ffdf48-dc5f-4178-bb80-9538135ac39c",
      name: "Борщ",
      is_default: false,
      children: [
        {
          id: "971cbfe4-f302-11ec-b939-0242ac120002",
          name: "Мясо",
          value: "41.35995",
          
          children: [
            {
              id: "31606cef-0e99-4354-91bc-521b1b7e42c5",
              name: "Говядина с костями",
              
              value: 100
            }
          ]
        },
        {
          id: "971cc458-f302-11ec-b939-0242ac120002",
          name: "Овощи",
          value: "52.55771",
          
          children: [
            {
              id: "a88025da-a6a6-4f87-9e93-c93a6b6ad7e5",
              name: "Капуста белокочанная",
              
              value: 16.795252304561973
            },
            {
              id: "2a49bd7e-f1fa-4c2b-b4f8-7f38df7a7d81",
              name: "Картофель",
              
              value: 40.7121619263853
            },
            {
              id: "b434ab88-1439-4859-a10e-295876989934",
              name: "Свекла",
              
              value: 6.172110618974838
            },
            {
              id: "77df7b39-6052-4b75-ac76-e7bdc9460900",
              name: "Морковь",
              
              value: 14.124626053912928
            },
            {
              id: "8683202d-ea87-4039-b2e2-65f91808bfb3",
              name: "Лук репчатый",
              
              value: 22.19584909616496
            }
          ]
        },
        {
          id: "971cc610-f302-11ec-b939-0242ac120002",
          name: "Продукты питания, не отнесенные к другим категориям",
          value: "6.08235",
          
          children: [
            {
              id: "7aad141a-3c9a-4fd8-85bc-7367c445afef",
              name: "Томатная паста",
              
              value: 100
            }
          ]
        }
      ],
      "basket_total_weight": 100.00001
    },
    {
      id: "d81dc366-0c92-4ccf-accf-69625f8f1ee9",
      name: "Тестовая",
      is_default: false,
      children: [
        {
          id: "971cc1ce-f302-11ec-b939-0242ac120002",
          name: "Молочные продукты",
          value: null,
          
          children: []
        }
      ],
      "basket_total_weight": null
    }
  ];