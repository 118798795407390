import usePagination from '@/uses/usePagination'
import {useRouter} from 'vue-router'
import {ref, Ref} from 'vue'

export default function () {
  const {page, totalPage, itemPerPage, getRowNumber} = usePagination()
  const router = useRouter()
  const query: Ref<{
    [k: string]: string | number
  }> = ref({})

  const addQueryParam = (key: string, value: string | number) => {
    if (value === '') {
      delete query.value[key]
      return
    }
    query.value[key] = value
  }
  const updatePage = async (search = '') => {
    addQueryParam('page', page.value)
    addQueryParam('search', search)

    await router.push({
      query: query.value,
    })
  }

  return {
    itemPerPage,
    page,
    totalPage,
    getRowNumber,
    updatePage,
  }
}
