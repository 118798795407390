import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {LinearPoint} from '@/types/LinearPoint'
import {ProductServiceDict} from '@/types/ProductServiceDict'
import {NewsDict} from '@/types/NewsDict'

export type APIResponseDict =
  | {error?: null; data?: null}
  | {error?: unknown; data?: null}
  | {error?: unknown; data?: LinearPoint}

export type APIResponseProductService =
  | {error?: null; data: ProductServiceDict[]}
  | {error?: unknown; data: ProductServiceDict[]}
  | {error: unknown; data?: null}

export type APIResponseNews =
  | {error?: null; data: NewsDict[]}
  | {error?: unknown; data: NewsDict[]}
  | {error: unknown; data?: null}

const api: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_MAIN_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

export default class BaseApiService {
  protected axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = api
  }

  async getLinear(): Promise<APIResponseDict> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseDict>(
        '/report/getAllCostChart'
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }

  async getProductCostChart(productId: string): Promise<APIResponseDict> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseDict>(
        `/report/getProductCostChart/${productId}`
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }

  async getProductService(): Promise<APIResponseProductService> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseProductService>(
        '/report/getProductServiceDict'
      )
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }

  async getNews(): Promise<APIResponseNews> {
    try {
      const {data} = await this.axiosInstance.get<APIResponseNews>('/news')
      return {error: null, data: data.data}
    } catch (error) {
      console.log(error)
      return {error: error, data: null}
    }
  }
}
