import {LinearPoint} from '@/types/LinearPoint'

export class LinearCurrencyDataSet {
  label?: string
  type = 'line'
  data = [] as LinearPoint[]
  fill = false
  borderColor = '#D55669'
  tension = 0
  backgroundColor = '#D55669'
  pointRadius = 2
  xAxisID = 'xcurrency'
  borderWidth = 1
  stepped = false
}
