import store from './store'
import {checkCookie, getCookie} from './js/utils'

if (checkCookie('user_guide')) {
  const user_guide = getCookie('user_guide')
  console.log(user_guide)
  store.dispatch('updateUserGuide', user_guide)
  console.log(store.getters.user_guide)
  console.log(store.getters.has_user_guide)
}
