export class LinearChartOptions {
  responsive = true
  type = 'line'
  lastindex = 0
  lastDataSet = 0
  maintainAspectRatio = false
  tooltips = {
    mode: 'index',
    intersect: true,
  }
  onClick = (e: any, elements: []): void => {
    return
  }
  onHover = (e: any, chartElement: any) => {
    const target = e.native ? e.native.target : e.target
    target.style.cursor = chartElement[0] ? 'pointer' : 'default'
  }
  layout = {
    padding: {
      right: 24,
    },
  }
  parsing = [
    {
      xAxisKey: 'x',
      yAxisKey: 'y',
    },
  ]
  plugins = {
    tooltip: {
      callbacks: {},
    },
    legend: {
      display: true,
      position: 'bottom',
      align: 'end',
      labels: {
        padding: 30,
        usePointStyle: true,
        boxWidth: 6,
        boxHeight: 6,
        color: '0066CC',
        pointStyle: 'circle',
        backgroundColor: 'red',
        fill: false,
        borderRadius: 2,
        useBorderRadius: false,
        font: {
          size: 16,
          family: 'Montserrat',
        },
      },
    },
    datalabels: {
      // listeners: {
      //   enter: function (context: any, event: any) {
      //     // Receives `enter` events for any labels of any dataset. Indices of the
      //     // clicked label are: `context.datasetIndex` and `context.dataIndex`.
      //     // For example, we can modify keep track of the hovered state and
      //     // return `true` to update the label and re-render the chart.
      //     context.hovered = true;
      //     return true;
      //   },
      //   leave: function (context: any, event: any) {
      //     // Receives `leave` events for any labels of any dataset.
      //     context.hovered = false;
      //     return true;
      //   },
      // },
      enabled: function (context: any) {
        // Change the label text color based on our new `hovered` context value.
        return context.hovered ? false : false
      },
      display: true,
      // color: function (context: any) {
      //   // Change the label text color based on our new `hovered` context value.
      //   return context.hovered ? "blue" : "red";
      // },
      formatter: (val: any, ctx: any) => {
        if (ctx.datasetIndex != this.lastDataSet) {
          this.lastDataSet = ctx.datasetIndex
          this.lastindex = 0
        }
        if (
          !ctx.active &&
          ctx.dataIndex != 0 &&
          ctx.dataIndex != ctx.dataset.data.length - 1
        ) {
          if (
            (ctx.dataset.data[ctx.dataIndex].cpi -
              ctx.dataset.data[ctx.dataIndex - 1].cpi) *
              (ctx.dataset.data[ctx.dataIndex + 1].cpi -
                ctx.dataset.data[ctx.dataIndex].cpi) <
            0
          ) {
            this.lastindex = ctx.dataIndex
            return ctx.dataset.data[ctx.dataIndex].cpi.toFixed(2) + '%'
          }
        }
        return ''
      },
      font: {
        size: 10,
        weight: 500,
      },
      align: function () {
        return 300
      },
      color: function (context: any) {
        return context.dataset.borderColor
      },
    },
  }
  scales = {
    x: {
      position: 'top',
      ticks: {
        font: {
          size: 12,
          family: 'Montserrat',
        },
      },
      grid: {
        drawBorder: false,
      },
      type: 'category',
    },
    y: {
      ticks: {
        font: {
          family: 'Montserrat',
          weight: 'bold',
        },
        callback: (value: any) => {
          return `${value} %`
        },
        // color: "#0066CC",
        precision: 1,
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
      },
    },
    xcurrency: {
      id: 'xCurrency',
      type: 'category',
      position: 'bottom',
      display: false,
      // ticks: {
      //   min: 0,
      //   max: 285,
      //   stepSize: 29,
      // },
    },
    // ycurrency: {
    //   id: "yCurrency",
    //   position: "right",
    //   display: true,
    //   ticks: {
    //     font: {
    //       family: "Montserrat",
    //       weight: "bold",
    //     },
    //     color: "#D55669",
    //     callback: (value: any) => {
    //       return `${value} %`;
    //     },
    //     precision: 1,
    //   },
    // },
  }
}
